import React, { FC } from 'react'

interface ContainerProps {
  children: React.ReactNode
  className?: string
}
const Container: FC<ContainerProps> = ({ children, className }) => (
    <div className={`mx-auto px-5 2xl:container ${className || ''}`}>
      {children}
    </div>
  )

export default Container
