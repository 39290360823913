import { useRouter } from 'next/router'
import { FC, useRef, useState } from 'react'
import IconButton from './icon-button'
import { SWEDISH, NORWEGIAN, ENGLISH } from '../lib/constants'
import Tooltip from './tooltip'
import SwedenFlag from '../public/se-flag.svg'
import NorwayFlag from '../public/no-flag.svg'
import EnglishFlag from '../public/en-flag.svg'
import {
  baseLanguageURLs,
  useCurrentLocale,
  useTranslation,
} from '../lib/utils'
import { countriesType, headerColorThemeType } from '../lib/types'

interface CountryButtonWithTooltipProps {
  href: string
  ariaLabel: string
  icon: JSX.Element
  lang: string
  ariaCurrent?: undefined | 'page'
  colorTheme: headerColorThemeType
  isBad?: boolean
}
const CountryButtonWithTooltip: FC<CountryButtonWithTooltipProps> = ({
  href,
  ariaLabel,
  icon,
  lang,
  ariaCurrent = undefined,
  colorTheme = 'blue',
  isBad = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const buttonRef = useRef<HTMLButtonElement>(null)
  return (
    <div className="flex flex-col items-center">
      <IconButton
        // Do not use aria-current if the website is bad
        ariaCurrent={ariaCurrent && !isBad ? 'page' : undefined}
        href={href}
        ariaLabel={ariaLabel}
        icon={icon}
        lang={lang}
        ref={buttonRef}
        onMouseEnter={() => {
          setIsOpen(true)
        }}
        onMouseLeave={() => setIsOpen(false)}
        onFocus={() => !isBad && setIsOpen(true)}
        onBlur={() => !isBad && setIsOpen(false)}
        className={
          isBad
            ? 'focus-visible:xs:!outline-0 lg:focus-visible:xs:!outline-8'
            : ''
        }
      />
      {ariaCurrent && (
        <div
          className={`mt-3 h-[0.375rem] w-[60%] rounded-full ${
            colorTheme === 'yellow' || colorTheme === 'red'
              ? 'bg-black'
              : 'bg-white'
          } dark:bg-white`}
        />
      )}
      <Tooltip title={ariaLabel} anchorEl={buttonRef.current} isOpen={isOpen} />
    </div>
  )
}
interface CountrySelectorProps {
  colorTheme?: headerColorThemeType
  isBad?: boolean
}
const CountrySelector: FC<CountrySelectorProps> = ({
  colorTheme = 'blue',
  isBad = false,
}) => {
  const router = useRouter()
  const locale = useCurrentLocale()
  const countries = process.env.LOCALES?.split(',') as Array<countriesType>
  const t = useTranslation()
  const getLanguageName = (country: countriesType) =>
    ({
      sv: SWEDISH,
      no: NORWEGIAN,
      en: ENGLISH,
    }[country])
  const getFlagIcon = (country: countriesType) =>
    ({
      sv: <SwedenFlag width="24" height="24" viewBox="2 0 10 10" />,
      no: <NorwayFlag width="24" height="24" viewBox="100 0 800 800" />,
      en: <EnglishFlag width="24" height="24" viewBox="9 0 38 38" />,
    }[country])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {countries
        ? countries?.map((country) => (
            <CountryButtonWithTooltip
              key={country}
              href={
                country === router.defaultLocale
                  ? '/'
                  : `${baseLanguageURLs(country)?.replace(/\/+$/, '')}`
              }
              ariaCurrent={country === locale ? 'page' : undefined}
              ariaLabel={
                country === locale
                  ? `${getLanguageName(country)}${
                      !isBad ? `, ${t.youAreHere}` : ''
                    }`
                  : getLanguageName(country)
              }
              icon={getFlagIcon(country)}
              lang={country}
              colorTheme={colorTheme}
              isBad={isBad}
            />
          ))
        : null}
    </>
  )
}

export default CountrySelector
