import React, { forwardRef, RefObject } from 'react'

interface IconButtonProps
  extends React.HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> {
  href?: string
  ariaLabel?: string
  src?: string
  className?: string
  icon?: React.ReactNode
  ariaCurrent?: 'page' | undefined
}
// eslint-disable-next-line react/display-name
const IconButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconButtonProps
>(
  (
    {
      href,
      ariaLabel,
      onClick,
      ariaCurrent = undefined,
      onMouseEnter,
      onMouseLeave,
      className,
      icon,
      ...other
    },
    ref
  ) =>
    href && !onClick ? (
      <a
        ref={ref as RefObject<HTMLAnchorElement>}
        href={href}
        className={`relative flex h-[3.5rem] w-[3.5rem] items-center overflow-hidden rounded-full bg-beige font-semibold transition-background hover:brightness-90 focus-visible:brightness-95 dark:bg-zinc-800 lg:h-[3rem] lg:w-[3rem] [&>svg]:h-full [&>svg]:w-full ${
          className || ''
        }`}
        aria-label={ariaLabel}
        {...other}
        aria-describedby={undefined}
        aria-current={ariaCurrent}
        title={undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {icon}
      </a>
    ) : (
      <button
        ref={ref as RefObject<HTMLButtonElement>}
        className={`${
          className || ''
        } flex h-[3.5rem] w-[3.5rem] items-center rounded-full bg-beige p-2 font-semibold transition-background hover:brightness-90 focus-visible:brightness-95 dark:bg-zinc-800 lg:h-[3rem] lg:w-[3rem] [&>svg]:h-full [&>svg]:w-full`}
        aria-label={ariaLabel}
        onClick={onClick}
        type="button"
        {...other}
        aria-describedby={undefined}
        title={undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {icon}
      </button>
    )
)

export default IconButton
