import Head from 'next/head'
import { useCurrentLocale } from '../lib/utils'

export default function Meta({ isBad = false }) {
  const locale = useCurrentLocale()
  const name = {
    sv: isBad
      ? 'Uselt - Vi gör tillgänglighet krångligt!'
      : 'Useit - Vi gör tillgänglighet enkelt!',
    no: 'Uselt - Vi gjør universell utforming kranglete!',
    en: 'Useit - Accessibility made simple!',
  }[locale]

  return (
    <Head>
      <meta name="application-name" content="Useit" />
      <meta name="referrer" content="no-referrer" />
      {isBad && (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      )}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#7ca7ba"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#7ca7ba" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta
        name="theme-color"
        media="(prefers-color-scheme: light)"
        content="#7ca7ba"
      />
      <meta
        name="theme-color"
        media="(prefers-color-scheme: dark)"
        content="#0f172a"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={name} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <link rel="alternate" type="application/rss+xml" href="/rss/feed.xml" />
      <title>Useit</title>
      {/*
      <meta property="og:image" content={HOME_OG_IMAGE_URL} />
*/}
    </Head>
  )
}
