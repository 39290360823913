import React, { FC } from 'react'

interface ButtonProps
  extends React.HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> {
  className?: string
  href?: string
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  variant?: 'standard' | 'small'
  align?: 'right' | 'center' | 'left' | 'none'
  name?: string
  value?: string
  ariaLabel?: string
  isBadNoSemantics?: boolean
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  href,
  onClick,
  variant = 'standard',
  align = 'none',
  ariaLabel,
  isBadNoSemantics,
  ...other
}) => {
  const buttonVariantStyles = {
    small: 'px-5 py-3 text-lg lg:text-xl rounded-xl',
    standard: 'min-w-[250px] px-10 py-6 text-xl lg:text-2xl rounded-xl',
  }[variant]

  const alignStyles = {
    right: 'flex ml-auto mr-0',
    left: 'flex ml-0 mr-auto',
    center: 'flex m-[0 auto]',
    none: 'inline-flex',
  }[align]

  const Cta = isBadNoSemantics ? 'div' : 'a'

  return href ? (
    // @ts-ignore
    <Cta
      // @ts-ignore
      onClick={() => {
        window.location.href = href
      }}
      href={href}
      className={`${className} focus-visible:box cursor-pointer justify-center bg-darkred ${alignStyles} ${buttonVariantStyles} text-center text-white no-underline transition-colors hover:brightness-95 focus-visible:brightness-95 forced-colors:border-2 forced-colors:border-transparent`}
      aria-label={ariaLabel}
      {...other}
    >
      {children}
    </Cta>
  ) : (
    // @ts-ignore
    <Cta
      type="button"
      onClick={onClick}
      className={`${className} ${buttonVariantStyles} ${alignStyles} cursor-pointer justify-center bg-darkred text-center text-white transition-colors hover:brightness-95 focus-visible:brightness-95 forced-colors:border-2 forced-colors:border-transparent`}
      aria-label={ariaLabel}
      {...other}
    >
      {ariaLabel}
      {children}
    </Cta>
  )
}

export default Button
