import { useRef, useState } from 'react'
import IconButton from './icon-button'
import { usePreferredTheme, useTranslation } from '../lib/utils'
import Tooltip from './tooltip'
import Light from '../public/light.svg'
import Dark from '../public/dark.svg'

function DarkModeSelector({ isBad = false }) {
  const { preferredTheme, setTheme } = usePreferredTheme()
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const t = useTranslation()
  const badLabel = preferredTheme === 'dark' ? 'Sol' : 'Måne'

  return (
    <>
      <IconButton
        src={preferredTheme === 'dark' ? '/light.svg' : '/dark.svg'}
        ariaLabel={
          isBad
            ? badLabel
            : `${t.showIn} ${
                preferredTheme === 'dark' ? t.lightMode : t.darkMode
              }`
        }
        onClick={() => {
          setTheme(preferredTheme === 'dark' ? 'light' : 'dark')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(window as any).dataLayer?.push({ event: 'toggle_darkmode' })
        }}
        icon={preferredTheme === 'dark' ? <Light /> : <Dark />}
        ref={buttonRef}
        onMouseEnter={() => {
          setIsOpen(true)
        }}
        onMouseLeave={() => setIsOpen(false)}
        onFocus={() => (!isBad ? setIsOpen(true) : null)}
        onBlur={() => (!isBad ? setIsOpen(false) : null)}
      />
      <Tooltip
        isOpen={isOpen}
        anchorEl={buttonRef.current}
        title={`${t.showIn} ${
          preferredTheme === 'dark' ? t.lightMode : t.darkMode
        }`}
      />
    </>
  )
}
export default DarkModeSelector
