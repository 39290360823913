import { FC, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { COMPANY_NAME } from '../lib/constants'
import UseitLogo from '../public/useit.svg'
import BadLogo from '../public/uselt.svg'
import MobileMenu from './mobile-menu'
import Tooltip from './tooltip'
import DesktopMenu from './desktop-menu'
import { localizedPaths, useCurrentLocale, useTranslation } from '../lib/utils'
import { allMenuItemsType, headerColorThemeType } from '../lib/types'
import { badContent } from '../lib/badUtils'

interface IntroProps {
  allMenuItems: allMenuItemsType
  colorTheme?: headerColorThemeType
  isBad?: boolean
}
const Intro: FC<IntroProps> = ({
  allMenuItems,
  colorTheme = 'blue',
  isBad = false,
}) => {
  const router = useRouter()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const t = useTranslation()
  const locale = useCurrentLocale()

  const badOptions = badContent.menu[locale]

  const menuItemsPlusContact: IntroProps['allMenuItems'] = allMenuItems && [
    ...allMenuItems,
    {
      title: t.contact,
      target: localizedPaths(locale).contact,
    },
  ]

  const badMenuItems: IntroProps['allMenuItems'] = allMenuItems && [
    {
      title: badOptions.news,
      target: localizedPaths(locale).posts,
    },
    {
      title: badOptions.services,
      target: localizedPaths(locale).services,
    },
    {
      title: badOptions.about,
      target: localizedPaths(locale).about,
    },
    {
      title: badOptions.contact,
      target: localizedPaths(locale).contact,
    },
  ]

  const Logo = isBad ? BadLogo : UseitLogo

  const logoRef = useRef(null)

  return (
    <div className="flex flex-wrap justify-between">
      <div className="relative z-50 mb-4 mr-2 lg:mr-12">
        {router.pathname === '/' ? (
          <Logo
            viewBox={isBad ? '0 0 209 210' : '0 0 351 352'}
            width={125}
            height={125}
            className="transition-all"
            role="img"
            aria-label={`${COMPANY_NAME} ${t.logo}`}
          />
        ) : (
          <div ref={logoRef}>
            <Link
              href="/"
              className="block"
              aria-label={`${COMPANY_NAME}, ${t.goToStart}`}
            >
              <Logo
                viewBox={isBad ? '0 0 202 203' : '0 0 351 352'}
                width={125}
                height={125}
                className="transition-all"
                role="img"
                onMouseEnter={() => {
                  setTooltipIsOpen(true)
                }}
                onMouseLeave={() => setTooltipIsOpen(false)}
                onFocus={() => setTooltipIsOpen(true)}
                onBlur={() => setTooltipIsOpen(false)}
                aria-label={`${COMPANY_NAME}, ${t.goToStart}`}
              />
            </Link>
            <Tooltip
              isOpen={tooltipIsOpen}
              title={`${COMPANY_NAME}, ${t.goToStart}`}
              anchorEl={logoRef.current}
            />
          </div>
        )}
      </div>
      <MobileMenu
        allMenuItems={isBad ? badMenuItems : menuItemsPlusContact}
        colorTheme={colorTheme}
        isBad={isBad}
      />
      <DesktopMenu
        allMenuItems={isBad ? badMenuItems : menuItemsPlusContact}
        colorTheme={colorTheme}
        isBad={isBad}
      />
    </div>
  )
}
export default Intro
