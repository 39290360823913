// eslint-disable-next-line import/no-extraneous-dependencies
import { useScrollTrigger } from '@mui/material'
import { MdClose as CloseIcon } from 'react-icons/md'
import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { ClickAwayListener } from '@mui/base'
import Link from 'next/link'
import DarkModeSelector from './dark-mode-selector'
import Hamburger from '../public/hamburger.svg'
import Container from './container'

import CountrySelector from './country-selector'
import { poppins, useCurrentLocale, useTranslation } from '../lib/utils'
import { allMenuItemsType, headerColorThemeType } from '../lib/types'

interface MobileMenuProps {
  allMenuItems: allMenuItemsType
  colorTheme: headerColorThemeType
  isBad?: boolean
}
const MobileMenu: FC<MobileMenuProps> = ({
  allMenuItems,
  colorTheme,
  isBad = false,
}) => {
  const router = useRouter()
  const t = useTranslation()
  const locale = useCurrentLocale()
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 125,
  })
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

  const [isMounted, setIsMounted] = useState(false)

  const allMobileMenuItems = allMenuItems && [
    { title: 'Start', target: '' },
    ...allMenuItems,
  ]
  const localesLength = process.env.LOCALES
    ? process.env.LOCALES?.split(',')?.length
    : 0

  const gridClassName = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
  }[localesLength]

  useEffect(() => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).dataLayer?.push({ event: 'reduced_motion_preferred' })
    }
  }, [])

  useEffect(() => {
    // Only set aria current after page load to avoid hydration error
    setIsMounted(true)
  }, [])

  // Useeffect to handle animation states
  useEffect(() => {
    if (mobileMenuOpen) setMobileMenuOpened(true)
    if (!mobileMenuOpen) setMobileMenuOpened(false)
  }, [mobileMenuOpen])

  const MobileMenuInner = (
    <div className="flex flex-row justify-end space-x-3 lg:hidden">
      {isMounted ? (
        <DarkModeSelector />
      ) : (
        <div className="flex h-[3.5rem] w-[3.5rem] lg:h-[3rem] lg:w-[3rem]" />
      )}
      <button
        type="button"
        aria-expanded={mobileMenuOpen}
        role={isBad ? 'menu' : undefined}
        onClick={() => {
          setMobileMenuOpen(!mobileMenuOpen)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(window as any).dataLayer?.push({ event: 'open_mobile_menu' })
        }}
        className="!focus-visible:outline-0 flex items-center rounded-full bg-beige py-2 px-4 text-lg font-semibold transition-background hover:brightness-90 focus-visible:brightness-95 dark:bg-zinc-800"
      >
        <Hamburger className="mr-2" />
        {t.menu}
      </button>
      {mobileMenuOpen && (
        <div
          role="dialog"
          aria-label={t.mainMenu}
          className={`${
            poppins.variable
          } fixed !mx-0 inset-0 z-[1300] block font-sans before:fixed before:inset-0 before:z-[-1] before:h-full before:w-full before:bg-[rgba(0,0,0,0.5)] before:transition-opacity before:transition-opacity before:content-[''] ${
            mobileMenuOpened
              ? 'backdrop-grayscale before:opacity-100'
              : 'before:opacity-0'
          }`}
        >
          <ClickAwayListener onClickAway={() => setMobileMenuOpen(false)}>
            <div
              className={`mr-0 ml-auto h-[100vh] w-[80vw] overflow-scroll bg-blue py-8 text-xl font-semibold backdrop-grayscale transition-all dark:border-l-[2px] dark:border-zinc-700 dark:bg-zinc-900 dark:text-white ${
                mobileMenuOpened ? 'translate-x-0' : 'translate-x-[100%]'
              } ${isScrolled ? 'pt-2' : ''}`}
            >
              <Container className="mx-0 flex flex-col">
                <button
                  type="button"
                  /* eslint-disable-next-line jsx-a11y/no-autofocus */
                  autoFocus
                  onClick={() => setMobileMenuOpen(false)}
                  className="mb-10 flex min-h-[3.5rem] w-[fit-content] min-w-[130px] items-center justify-center self-end rounded-full bg-beige py-2 px-4 text-lg font-semibold transition-background hover:brightness-90 focus-visible:outline focus-visible:outline-0 focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkblue focus-visible:brightness-95 dark:bg-zinc-900 dark:focus-visible:outline-white"
                >
                  <CloseIcon className="inline-flex text-2xl" />
                  {t.close}
                </button>
                <nav className="flex flex-col" aria-label={t.mainMenu}>
                  {allMobileMenuItems?.map((item) => (
                    <Link
                      key={item.title}
                      href={`/${
                        locale !== router.defaultLocale
                          ? `${locale}/${item.target}`
                          : item.target
                      }`}
                      aria-current={
                        isMounted && router.asPath.slice(1) === item.target
                          ? 'page'
                          : undefined
                      }
                      className="p-4 text-center text-2xl focus-visible:!outline-0 focus-visible:brightness-95"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.title}
                    </Link>
                  ))}
                </nav>
                <nav
                  aria-label={t.countryNav}
                  className={`mt-8 grid gap-4 self-center ${gridClassName}`}
                >
                  <CountrySelector colorTheme={colorTheme} isBad={isBad} />
                </nav>
              </Container>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </div>
  )

  return (
    <div
      className={`${
        isScrolled
          ? `fixed right-0 top-0 z-[1100] w-full border-b-[2px] py-2 transition-background dark:border-b-zinc-800 dark:bg-zinc-900 ${
              colorTheme === 'yellow'
                ? 'border-b-beige bg-yellow'
                : 'border-b-lightblue bg-blue'
            }`
          : ''
      } inline lg:hidden`}
    >
      {isScrolled ? <Container>{MobileMenuInner}</Container> : MobileMenuInner}
    </div>
  )
}
export default MobileMenu
