import React, { FC } from 'react'
import { colorType, orientationType } from '../lib/types'

interface TextBoxProps {
  children: React.ReactNode
  orientation?: orientationType
  color?: colorType | 'transparent'
  useProse?: boolean
  className?: string
}

const TextBox: FC<TextBoxProps> = ({
  children,
  orientation = 'center',
  color = 'beige',
  useProse = false,
  className,
}) => {
  const getOrientation = {
    left: 'lg:ml-auto',
    right: 'lg:mr-auto',
    center: 'lg:mx-auto',
  }[orientation]

  const getColor = {
    blue: 'bg-yellow',
    beige: 'bg-beige',
    yellow: 'bg-yellow',
    red: 'bg-red',
    white: 'bg-white',
    transparent: 'bg-transparent dark:bg-transparent',
  }[color]

  const getProseStyles = () => {
    if (useProse)
      return 'prose prose-lg prose-neutral dark:prose-invert prose-p:leading-[1.75]'
    return 'space-y-3'
  }

  return (
    <div
      className={`${
        className || ''
      } relative z-20 my-8 transition-background ${getProseStyles()} break-words rounded-t-2xl rounded-bl-2xl px-6 py-8 text-xl dark:bg-zinc-800 md:px-12 lg:max-w-xl ${getColor} ${getOrientation}`}
    >
      {children}
    </div>
  )
}

export default TextBox
