import React from 'react'
import Meta from './meta'
import Button from './button'
import Container from './container'
import { useCurrentLocale, useTranslation } from '../lib/utils'
import { badContent } from '../lib/badUtils'

function SkipLink() {
  const t = useTranslation()

  const handleSkip = () => {
    if (process.env.IS_BAD_WEBSITE === "true")
      return undefined
    const target: HTMLHeadingElement | null = document.querySelector('h1')
    target?.setAttribute('tabindex', '-1')
    return target?.focus()
  }
  return (
    <Container>
      <Button
        onClick={handleSkip}
        className="absolute top-8 z-[1000] translate-y-[-150%] bg-black transition focus:translate-y-0"
      >
        {t.skipToContent}
      </Button>
    </Container>
  )
}

interface LayoutProps {
  children: React.ReactNode
  isBad?: boolean
  showBadDisclaimer?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  children,
  isBad = false,
  showBadDisclaimer = true,
}) => {
  const locale =  useCurrentLocale()
  return <>
    <Meta isBad={isBad} />
    <SkipLink />
    {isBad && showBadDisclaimer && (
      <section aria-label="Disclaimer" className="bg-black py-12 text-white">
        <Container>
          <strong className="text-2xl font-bold">{badContent.startPage[locale].disclaimerHeading}</strong>
          <p className="!text-lg">
          {badContent.startPage[locale].disclaimerContent1}
          </p>
          <p className="!text-lg mt-4">
            {badContent.startPage[locale].disclaimerContent2}{' '}
            <a
              className="underline underline-offset-[0.2em] focus-visible:outline-white"
              href={locale === "sv" ? "https://useit.se" : "https://useit.no"}
            >
              {locale === "sv" ? "Useit.se" : "Useit.no"}
            </a>
            {badContent.startPage[locale].disclaimerContent3}{' '}
          </p>
        </Container>
      </section>
    )}
    {children}
  </>
}

export default Layout
