// eslint-disable-next-line import/prefer-default-export
export const badContent = {
    startPage: {
        no: {
            cta: "Kontakt oss!",
            disclaimerHeading: "Les dette først",
            disclaimerContent1: "Uselt.se er en testnettside hvor vi skapt problemer knyttet til universell utforming med vilje. Nettsiden brukes kun til pedagogiske formål.",
            disclaimerContent2: "Du finner Useit på vårt vanlige nettside ",
            disclaimerContent3: ". Den er universelt utformet - vi lover!",
            heading: "Vi gjør universell utforming kranglete!",
            imageAlt: "Tablett",
            karate: "Karate",
            letter: "Post",
            intro: "Vi på Uselt er ikke eksperter på universell utforming. Vi hjelper dere med å skape ekskluderende nettsider og apper som ikke fungerer for noen.",
            strategicHeading: "Strategisk arbeid",
            strategicContent: "Vi hjelper dere til å ta fram en langsiktig strategi for et ikke-vellyket arbeid innen universell utforming i virksomheten.",
            consultantsHeading: "Konsulenter som ikke kan universell utforming",
            consultantsContent: "Våres eksperter jobber tett med dere i deres prosesser, og fungerer som en sparringspartner innen ikke-universelt utformede løsninger og brukeropplevelser.",
            analysesHeading: "Analyser, kurs og utilgengelighetserklæring",
            analysesContent: "Vi styrker deres kompetanse om spørsmål rundt hva som ikke er universell utforming og støtter dere i det dere trenger akkurat nå.",
            badCtaAriaLabel: "Send oss en epost"            
        },
        sv: {
            cta: "Kontakta oss!",
            disclaimerHeading: "Läs detta först",
            disclaimerContent1: "Uselt punkt se är en testsajt där vi lagt in tillgänglighetsproblem med flit. Sajten används bara i utbildningssyfte.",
            disclaimerContent2: "Du hittar Useit på vår vanliga sajt",
            disclaimerContent3: ". Och den är fullt tillgänglig - vi lovar!",
            heading: "Vi gör tillgänglighet krångligt!",
            imageAlt: "Surfplatta",
            karate: "Karate",
            letter: "Brev",
            intro: "Vi på Uselt är experter på digital otillgänglighet. Vi hjälper dig skapa exkluderande hemsidor och appar som inte fungerar för någon.",
            strategicHeading: "Strategiskt arbete",
            strategicContent: "Vi hjälper dig ta fram en långsiktig strategi för ett lyckat otillgänglighetsarbete i din organisation.",
            consultantsHeading: "Otillgänglighetskonsulter",
            consultantsContent: "Våra experter arbetar nära dig i dina processer och agerar bollplank inom otillgänglighet, utveckling och UX.",
            analysesHeading: "Analyser, utbildningar och otillgänglighetsredogörelse",
            analysesContent: "Vi stärker er kompetens i otillgänglighetsfrågor och stödjer i det ni behöver just nu.",
            badCtaAriaLabel: "Mejla oss på Uselt"
        },
        en: {
            cta: "Kontakt oss!",
            disclaimerHeading: "Läs detta först",
            disclaimerContent1: "",
            disclaimerContent2: "",
            disclaimerContent3: "",
            heading: "We make accessibility difficult!",
            subheading: "",
            imageAlt: "iPad",
            karate: "Karate",
            letter: "Letter",
            intro: "We at Uselt are experts on digital inaccessibility. We help you create exclusive websites and apps that don't work for anyone.",
            strategicHeading: "Strategic work",
            strategicContent: "We help you develop a long-term strategy for successful inaccessibility work in your organization.",
            consultantsHeading: "Inaccessibility consultants",
            consultantsContent: "",
            analysesHeading: "Inaccessibility analysis",
            analysesContent: "",
            badCtaAriaLabel: "Mejla oss på Uselt"
            }
    },
    menu: {
        no: {
            news: 'Aktuelt',
            services: 'Vi tilbyr',
            about: 'Om Uselt',
            contact: 'Kontakt oss',

        },
        sv: {
            news: 'Aktuellt',
            services: 'Vi erbjuder',
            about: 'Om Uselt',
            contact: 'Kontakt',
        },
        en: {
            news: 'News',
            services: 'Services',
            about: 'About Uselt',
            contact: 'Contact',
            }
    },
    contactPage: {
        no: {

        },
        sv: {

        },
        en: {
                
            }
    },
    newsLetter: {
        no: {
            heading: "Abboner på Uselts nyhetsbrev",
            paragraph1: "Ta del av spennende artikler og inspirasjon om hvordan du kan gjøre ditt nettsted enda verre for brukerne.",
            paragraph2: "Vi sender ett nyhetsbrev om dagen.",
            consent: "Jeg godkjenner att Uselt behandler mine personopplysninger og formidler informasjonen til hvem de måtte ønske."
        },
        sv: {
            heading: "Prenumerera på Uselts nyhetsbred",
            paragraph1: "Ta del av spännande artiklar och inspiration om hur du kan göra din webbplats ännu sämre för användaren.",
            paragraph2: "Vi skickar ett nyhetsbrev om dagen.",
            consent: "Jag godkänner att Uselt behandlar mina personuppgifter."


        },
        en: {
            heading: "Abboner på Uselts nyhetsbrev",
            paragraph1: "Ta del av spennende artikler og inspirasjon om hvordan du kan gjøre ditt nettsted enda verre for brukerne.",
            paragraph2: "Vi sender ett nyhetsbrev om dagen",
            consent: "Jeg godkjenner ikke att Uselt behandler mine personopplysninger."

                
            }
    },
    servicesPage: {
        no: {
            mainHeading: "Vi tilbyr",
            introParagraph1: "Uavhengig om du jobber i en liten eller stor organisasjon, kan vi hjelpe deg med å mislykkes med dine mål innen universell utforming. Vi er spesialister på ikke-universelt utformede nettisder, apper og dokumenter.",
            introParagraph2: "Vi kan alt hvordan man bryter mot internasjonale standarder og svensk, norsk og europeiske lover innen universell utforming",
            strategicHeading: "Strategisk arbeid",
            strategicParapraph1: "Et langsiktig arbeid er nøkkelen for et vellykket arbeid som ikke er universelt utformet!",
            strategicParagraph2: "Vi ser på hvordan dere jobber i dag, definerer deres uoppnåelige mål og gir konkrete forslag på hvordan dere kan svekke universell utforming i deres løsninger.",
            strategicCta: "Snakke med oss om strategi",
            consultantsHeading: "Konsulenter som ikke kan universell utforming",
            consultantsParagraph1: "Våre eksperter jobber gjerne tett med dere i deres prosesser, og fungerer som en sparringspartner innen ikke-universelt utformede løsninger og brukeropplevelser.",
            consultantsParagraph2: "Vi bistår med kunnskap og hjelper dere bremse det daglige arbeidet med universell utforming.",
            consultantsCta: "Få hjelp av våre konsulenter",
            analysisHeading: "Analyser, kurs og utilgjengelighetserklæring",
            analysisParagraph1: "Et ekskluderende nettsted og digitalt fraværende er en viktig del av arbeidet med universell utforming. Vares eksperter hjelper dere å redusere kompetansen internt og svekke kunnskapen innen universell utforming i deres organisasjon.",
            analysisParagraph2: "Vi gjennomfører analyser som ikke er universelt utformet utifra WCAG 2.1 og EN 301 549 for å sikre at deres digitale tjenester ikke oppfyller lovkravene.",
            analysisParagraph3: "Vi tilbyr kurs og workshops om ikke universelt utformede løsninger.",
            analysisParagraph4: "Vi gjennomfører brukertester med testpersoner uten nedsatte funksjonsevner for å hindre at dere forstår brukernes behov.",
            analysisParagraph5: "Vi genomför användbarhetstester med testpersoner utan funktionsnedsättningar för att förhindra att du förstår användarnas behov.",
            analysisCta: "Jeg vil vite mer"

        },
        sv: {
            mainHeading: "Vi erbjuder",
            introParagraph1: "Oavsett om du arbetar i en liten eller stor organisation kan vi hjälpa dig att misslyckas med dina tillgänglighetsmål! Vi är specialister på otillgängliga webbplatser, appar och dokument.",
            introParagraph2: "Vi kan allt om hur man bryter mot internationella standarder och svensk, norsk och europeisk lagstiftning inom tillgänglighet.",
            strategicHeading: "Strategiskt arbete",
            strategicParapraph1: "Vi hjälper dig ta fram en långsiktig strategi för ett lyckat otillgänglighetsarbete i din organisation.",
            strategicParagraph2: "Vi tittar på hur din organisation arbetar idag, hjälper dig att definiera ouppnåliga mål och ger konkreta förslag på hur ni kan försämra er tillgänglighet.",
            strategicCta: "Prata med oss om strategi",
            consultantsHeading: "Otillgänglighetskonsulter",
            consultantsParagraph1: "Våra experter arbetar gärna nära dig i dina processer som en del av din organisation, eller som en del i ditt team. Vi bistår med kunskap och hjälper dig att driva det dagliga otillgänglighetsarbetet.",
            consultantsParagraph2: "Vi bistår med kunnskap och hjälper er bromsa det dagliga tillgänglighetsarbetet",
            consultantsCta: "Ta hjälp av våra konsulter",
            analysisHeading: "Analyser, utbildningar och otillgänglighetsredogörelse",
            analysisParagraph1: "Vi stärker er kompetens i otillgänglighetsfrågor och stödjer i det ni behöver just nu.",
            analysisParagraph2: "En exkluderande webbplats och digital närvaro är en viktig del av otillgänglighetsarbetet. Våra experter hjälper dig minska kompetensen internt och försvaga kunskapen inom tillgänglighet i er organisation. ",
            analysisParagraph3: "Vi genomför otillgänglighetsanalyser utifrån WCAG 2.1 och EN 301 549 för att säkerställa att din organisations digitala tjänster inte uppfyller lagkraven.",
            analysisParagraph4: "Vi erbjuder utbildningar och workshops om otillgänglighet.",
            analysisParagraph5: "Vi genomför användbarhetstester med testpersoner utan funktionsnedsättningar för att förhindra att du förstår användarnas behov.",
            analysisCta: "Jag vill veta mer"
        },
        en: {
            mainHeading: "Vi erbjuder",
            introParagraph1: "Oavsett om du arbetar i en liten eller stor organisation kan vi hjälpa dig att misslyckas med dina tillgänglighetsmål! Vi är specialister på otillgängliga webbplatser, appar och dokument.",
            introParagraph2: "Vi kan allt om hur man bryter mot internationella standarder och svensk, norsk och europeisk lagstiftning inom tillgänglighet.",
            strategicHeading: "Strategiskt arbete",
            strategicParapraph1: "Vi hjälper dig ta fram en långsiktig strategi för ett lyckat otillgänglighetsarbete i din organisation.",
            strategicParagraph2: "Vi tittar på hur din organisation arbetar idag, hjälper dig att definiera ouppnåliga mål och ger konkreta förslag på hur ni kan försämra er tillgänglighet.",
            strategicCta: "Prata med oss om strategi",
            consultantsHeading: "Otillgänglighetskonsulter",
            consultantsParagraph1: "Våra experter arbetar gärna nära dig i dina processer som en del av din organisation, eller som en del i ditt team. Vi bistår med kunskap och hjälper dig att driva det dagliga otillgänglighetsarbetet.",
            consultantsParagraph2: "Vi bistår med kunnskap og hjelper dere bremse det daglige arbeidet med universell utforming.",
            consultantsCta: "Ta hjälp av våra konsulter",
            analysisHeading: "Analyser, utbildningar och otillgänglighetsredogörelse",
            analysisParagraph1: "Vi stärker er kompetens i otillgänglighetsfrågor och stödjer i det ni behöver just nu.",
            analysisParagraph2: "En exkluderande webbplats och digital närvaro är en viktig del av otillgänglighetsarbetet. Våra experter hjälper dig minska kompetensen internt och försvaga kunskapen inom tillgänglighet i er organisation. ",
            analysisParagraph3: "Vi genomför otillgänglighetsanalyser utifrån WCAG 2.1 och EN 301 549 för att säkerställa att din organisations digitala tjänster inte uppfyller lagkraven.",
            analysisParagraph4: "Vi erbjuder utbildningar och workshops om otillgänglighet.",
            analysisParagraph5: "Vi genomför användbarhetstester med testpersoner utan funktionsnedsättningar för att förhindra att du förstår användarnas behov.",
            analysisCta: "Jag vill veta mer"
            }
    },
    aboutPage: {
        no: {
            aboutParagraph1: 'Uselt (som betyr “elendig” på norsk) ble etablert i 2023 som en parodi på Useit. Nettsiden brukes i kurs for å lære våre kunder med å teste sine digitale opplevelser og finne problemer knyttet til universell utforming.',
            aboutParagraph2: "Vi har lagt inn cirka 25 problemer knyttet til universell utforming på nettsiden. Klarer du å finne alle?",
            role: "Ikke-ekspert på universell utforming"
        },
        sv: {
            aboutParagraph1: "Uselt skapades 2023 som en parodi på Useit. Sajten används i utbildningar för att lära våra kunder att testa sina digitala upplevelser och hitta tillgänglighetsproblem.",
            aboutParagraph2: "Vi har lagt in cirka 25 tillgänglighetsproblem på sajten. Kan du hitta alla?",
            role: "Otillgänglighetsexpert"
        },
        en: {
            aboutParagraph1: "Uselt was created in 2023 as a parody of Useit. The site is used in training to teach our customers to test their digital experiences and find accessibility problems.",
            aboutParagraph2: "We have added about 25 accessibility problems to the site. Can you find them all?",
            role: "Otillgänglighetsexpert"
            }
    },
    postsPage: {
        no: {
            heading: "Aktuelt hos Uselt",
            intro: ""

        },
        sv: {
            heading: "Aktuellt hos Uselt",
            intro: "Artiklar och nyheter om digital otillgänglighet"
        },
        en: {
            heading: "Aktuellt hos Uselt",
            intro: ""
                
            }
    },
    footer: {
        no: {

        },
        sv: {

        },
        en: {
                
            }
    },
}