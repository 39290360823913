import { SocialProfileJsonLd } from 'next-seo'
import { MdEmail as Email, MdPhone as Phone } from 'react-icons/md'
import {
  BsLinkedin as LinkedIn,
  BsFacebook as Facebook,
  BsInstagram as Instagram,
} from 'react-icons/bs'
import Link from 'next/link'
import { FC } from 'react'
import Container from './container'
import { ENGLISH, NORWEGIAN, SWEDISH } from '../lib/constants'
import SwedenFlag from '../public/se-flag.svg'
import NorwayFlag from '../public/no-flag.svg'
import EnglishFlag from '../public/en-flag.svg'
import {
  baseLanguageURLs,
  localizedPaths,
  useCurrentLocale,
  useTranslation,
} from '../lib/utils'
import { countriesType, SiteDataProps } from '../lib/types'

type FooterProps = {
  siteData: SiteDataProps
  // eslint-disable-next-line react/no-unused-prop-types
  showNewsletter?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  isBad?: boolean
}

const UseitNorwayContacts: FC<FooterProps> = ({ siteData }) => (
  <div className="space-y-2">
    <h4 className="text-base font-semibold">
      {siteData?.useitnorway?.companyname}
    </h4>
    <div className="space-y-1">
      <p className="text-base">{siteData?.useitnorway?.address1}</p>
      <p className="text-base">{siteData?.useitnorway?.address2}</p>
      <p className="text-base">Org: {siteData?.useitnorway?.org}</p>
    </div>
  </div>
)
const UseitSwedenContacts: FC<FooterProps> = ({ siteData }) => (
  <div className="space-y-2">
    <h4 className="text-base font-semibold">
      {siteData?.useitsweden?.companyname}
    </h4>
    <div className="space-y-1">
      <p className="text-base">{siteData?.useitsweden?.address1}</p>
      <p className="text-base">{siteData?.useitsweden?.address2}</p>
      <p className="text-base">Org: {siteData?.useitsweden?.org}</p>
    </div>
  </div>
)
const Footer: FC<FooterProps> = ({
  siteData,
  showNewsletter = false,
  isBad = false,
}) => {
  const locale = useCurrentLocale()

  const locales = process.env.LOCALES?.split(',') as Array<countriesType>
  const t = useTranslation()

  const currentCompanyInfo = {
    sv: siteData.useitsweden,
    no: siteData.useitnorway,
    en: siteData.useitsweden,
  }[locale || 'sv']

  const footerLanguageInfo = (language: countriesType) =>
    ({
      sv: {
        name: SWEDISH,
        icon: (
          <SwedenFlag
            width="24"
            height="24"
            aria-hidden={isBad ? undefined : 'true'}
            viewBox="2 0 10 10"
            role={isBad ? 'img' : undefined}
            aria-label={isBad ? 'Svensk flagga' : undefined}
          />
        ),
        target: baseLanguageURLs(language),
      },
      en: {
        name: ENGLISH,
        icon: (
          <EnglishFlag
            width="24"
            height="24"
            viewBox="9 0 38 38"
            aria-hidden={isBad ? undefined : 'true'}
            role={isBad ? 'img' : undefined}
            aria-label={isBad ? 'English flag' : undefined}
          />
        ),
        target: baseLanguageURLs(language),
      },
      no: {
        name: NORWEGIAN,
        icon: (
          <NorwayFlag
            width="24"
            height="24"
            viewBox="100 0 800 800"
            aria-label={isBad ? 'Norsk flagga' : undefined}
            aria-hidden={isBad ? undefined : 'true'}
            role={isBad ? 'img' : undefined}
          />
        ),
        target: baseLanguageURLs(language),
      },
    }[language])

  return (
    <footer className="bg-[#262626] py-20 text-white text-opacity-70 transition-background">
      <h2 className="sr-only">{t.footer}</h2>
      <Container
        className={`flex grid ${
          showNewsletter ? 'lg:grid-cols-3' : 'lg:grid-cols-2'
        }`}
      >
        <div className="mb-12 flex flex-col space-y-6">
          <h3 className="text-2xl font-semibold lg:text-2xl">
            {t.footerContacts}
          </h3>
          <div className="w-fit space-y-3 pb-4">
            <Link
              href={`tel:${currentCompanyInfo?.phone?.replace(/\s/g, '')}`}
              className="flex items-center space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-0"
            >
              <Phone
                className="inline-flex text-2xl"
                role="img"
                aria-label="Telefonlur"
              />
              <span>{currentCompanyInfo?.phone}</span>
            </Link>
            <Link
              href={`mailto:${currentCompanyInfo?.email}`}
              className="flex items-center space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-0"
            >
              <Email
                className="inline-flex text-2xl"
                role="img"
                aria-label="Kuvert"
              />
              <span>{currentCompanyInfo?.email}</span>
            </Link>
            <br />
            <div className="space-y-3">
              {currentCompanyInfo?.linkedin && (
                <Link
                  href={currentCompanyInfo?.linkedin}
                  className="flex items-center space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-0"
                >
                  <LinkedIn
                    width="24"
                    height="24"
                    className="inline-flex"
                    role="img"
                    aria-label="Linkedin logotyp"
                  />
                  <span>Linkedin</span>
                </Link>
              )}
              {currentCompanyInfo?.facebook && (
                <Link
                  href={currentCompanyInfo?.facebook}
                  className="flex items-center space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-0"
                >
                  <Facebook
                    width="24"
                    height="24"
                    className="inline-flex"
                    role="img"
                    aria-label="Facebook logotyp"
                  />
                  <span>Facebook</span>
                </Link>
              )}
              {currentCompanyInfo?.instagram && (
                <Link
                  href={currentCompanyInfo.instagram}
                  className="flex items-center space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-0"
                >
                  <Instagram
                    width="24"
                    height="24"
                    className="inline-flex"
                    role="img"
                    aria-label="Instagram logotyp"
                  />
                  <span>Instagram</span>
                </Link>
              )}
            </div>

            {siteData?.useitsweden?.companyname &&
              siteData?.useitsweden?.facebook &&
              siteData?.useitsweden.linkedin &&
              process.env.USEIT_SE_URL && (
                <SocialProfileJsonLd
                  keyOverride={siteData?.useitsweden?.companyname}
                  type="Organization"
                  name={siteData?.useitsweden?.companyname}
                  url={process.env.USEIT_SE_URL}
                  sameAs={[
                    siteData?.useitsweden.facebook,
                    siteData?.useitsweden.linkedin,
                  ]}
                />
              )}

            {siteData?.useitnorway?.companyname &&
              siteData?.useitnorway?.facebook &&
              siteData?.useitnorway.linkedin &&
              process.env.USEIT_NO_URL && (
                <SocialProfileJsonLd
                  keyOverride={siteData?.useitnorway?.companyname}
                  type="Organization"
                  name={siteData?.useitnorway?.companyname}
                  url={process.env.USEIT_NO_URL}
                  sameAs={[
                    siteData?.useitnorway.facebook,
                    siteData?.useitnorway.linkedin,
                  ]}
                />
              )}
          </div>

          {locale === 'no' && <UseitNorwayContacts siteData={siteData} />}
          {locale === 'sv' && <UseitSwedenContacts siteData={siteData} />}
          {locale === 'en' && (
            <>
              <UseitSwedenContacts siteData={siteData} />
              <UseitNorwayContacts siteData={siteData} />
            </>
          )}
        </div>
        <div className="mb-12 space-y-6">
          <h3 className="text-2xl font-semibold">{t.language}</h3>
          <div className="flex w-fit flex-col space-y-3">
            {locales &&
              locales.map((country) => (
                <Link
                  key={country}
                  href={footerLanguageInfo(country).target as string}
                  className="flex space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-0 focus-visible:!outline-white"
                >
                  <div className="inline-flex h-[24px] w-[24px] self-center overflow-hidden rounded-full">
                    {footerLanguageInfo(country).icon}
                  </div>
                  <span>{footerLanguageInfo(country).name}</span>
                </Link>
              ))}
          </div>
        </div>
        {showNewsletter && (
          <div className="mb-12 space-y-6">
            <h3 className="text-2xl font-semibold">{t.moreFromUseit}</h3>
            <div className="flex w-fit flex-col space-y-3">
              <Link
                href={`/${localizedPaths(locale).newsletter}`}
                className="flex space-x-2 text-lg underline underline-offset-[0.2em] hover:brightness-90 focus-visible:!outline-white"
              >
                {t.signupForNewsletter}
              </Link>
              <br />
            </div>
          </div>
        )}
      </Container>
    </footer>
  )
}
export default Footer
