import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import CountrySelector from './country-selector'
import DarkModeSelector from './dark-mode-selector'
import { allMenuItemsType, headerColorThemeType } from '../lib/types'
import { useCurrentLocale, useTranslation } from '../lib/utils'

interface DesktopMenuProps {
  colorTheme: headerColorThemeType
  allMenuItems: allMenuItemsType
  isBad?: boolean
}
const DesktopMenu: FC<DesktopMenuProps> = ({
  allMenuItems,
  colorTheme = 'blue',
  isBad = false,
}) => {
  const router = useRouter()
  const locale = useCurrentLocale()
  const [isMounted, setIsMounted] = useState(false)
  const t = useTranslation()

  const localesLength = process.env.LOCALES
    ? process.env.LOCALES?.split(',')?.length
    : 0

  useEffect(() => {
    // Only set aria current after page load to avoid hydration error
    setIsMounted(true)
  }, [])

  // Super ugly way of setting classes depending on number of languages, but the nice way didnt work
  const countrySelectorClassName = {
    1: 'col-span-1 grid grid-cols-1',
    2: 'col-span-2 grid grid-cols-2',
    3: 'col-span-3 grid grid-cols-3',
  }[localesLength]

  const gridClassName = {
    1: 'grid-cols-2',
    2: 'grid-cols-3',
    3: 'grid-cols-4',
  }[localesLength]

  return (
    <div className="z-50 hidden flex-1 flex-wrap-reverse justify-between lg:flex">
      {allMenuItems?.length > 0 ? (
        <div
          className="inline flex-1"
          aria-label={!isBad ? t.mainMenu : undefined}
        >
          <div className="flex flex-wrap">
            <div className="m-0 inline-flex list-none flex-wrap rounded-2xl bg-beige py-6 px-2 transition-background dark:bg-zinc-800 lg:px-4">
              {allMenuItems.map((item) => {
                const ariaCurrent = isMounted && router.asPath.slice(1) === item.target

                return (
                <div key={item.title} className="m-0">
                  <Link
                    href={`/${
                      locale !== router.defaultLocale
                        ? `${locale}/${item.target}`
                        : item.target
                    }`}
                    aria-current={ariaCurrent
                        ? 'page'
                        : undefined
                    }
                    className={`${ariaCurrent ? "" : "opacity-50"} my-1 w-full px-4 text-lg underline-offset-[0.15em] hover:underline focus-visible:outline lg:w-auto lg:text-xl`}
                  >
                    {item.title}
                  </Link>
                </div>
              )})}
            </div>
          </div>
        </div>
      ) : (
        <div className="inline flex-1" />
      )}
      <div className={`grid gap-4 pl-6 pb-4 ${gridClassName}`}>
        <div className={`gap-4 ${countrySelectorClassName}`}>
          <CountrySelector colorTheme={colorTheme} isBad={isBad} />
        </div>
        {isMounted && (
          <div className="grid gap-4">
            <DarkModeSelector isBad={isBad} />
          </div>
        )}
      </div>
    </div>
  )
}
export default DesktopMenu
