import React, { useState, useEffect, FC } from 'react'
import { PopperUnstyled, PopperUnstyledProps } from '@mui/base'
import { poppins } from '../lib/utils'

interface TooltipProps {
  title: string
  anchorEl: PopperUnstyledProps['anchorEl']
  isOpen?: PopperUnstyledProps['open']
}
const Tooltip: FC<TooltipProps> = ({ title, anchorEl, isOpen = false }) => {
  const [isOpened, setIsOpened] = useState(false)
  useEffect(() => {
    // Only trigger tooltip on non-touch devices
    const isTouchscreen =
      window.matchMedia('(hover: none)').matches &&
      window.matchMedia('(pointer: coarse)').matches
    if (isOpen && !isTouchscreen) setIsOpened(true)
    if (!isOpen) setIsOpened(false)
  }, [isOpen])
  return (
    <PopperUnstyled
      open={isOpen}
      anchorEl={anchorEl}
      className={`z-[1300] ${poppins.variable} font-sans`}
    >
      <div
        className={`delay-250 relative mt-[14px] max-w-[300px] rounded-[4px] bg-[#515557] px-2 py-1 font-normal text-white transition-opacity ${
          isOpened ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className="absolute top-0 left-[50%] z-[-1] h-[1em] w-[1em] translate-x-[-50%] translate-y-[50%] rotate-45 bg-[#515557]" />
        {title}
      </div>
    </PopperUnstyled>
  )
}

export default Tooltip
